import React from "react";
import { Controller, useFormContext, useFieldArray } from "react-hook-form";
import { Input, InputMask, InputSelect } from "..";
import { EstablishmentUnitContactType } from "interfaces/EstablishmentUnit";

interface Props {
  addressIndex: number;
}

export function Contact({ addressIndex }: Props) {
  const prefix = "contacts";
  const { control, register } = useFormContext();
  const { fields, append, remove } = useFieldArray({
    control,
    name: `address[${addressIndex}].${prefix}`,
  });

  function handleAdd() {
    append({
      name: "",
      email: "",
      phone: "",
    });
  }

  const options = Object.entries(EstablishmentUnitContactType)
    .filter(([key, _]) => key !== "legalResponsible")
    .map(([value, label]) => ({
      value,
      label,
    }));

  function getErrorMessage(errors: any, index: number, fieldName: string) {
    if (
      errors?.address &&
      errors?.address[addressIndex] &&
      errors?.address[addressIndex].contacts
    ) {
      if (errors?.address[addressIndex]?.contacts[index]?.[fieldName]?.message)
        return errors?.address[addressIndex]?.contacts[index]?.[fieldName]
          ?.message;
    }
    return "";
  }

  return (
    <div className="pt-5 col-12 align-content-start d-flex flex-wrap">
      <h3>CONTATOS DESTA UNIDADE</h3>
      <p>
        Ao longo do ano, precisamos falar com vocês! Por isso, adicione o máximo
        de contatos possível para que a equipe do Tour consiga contatar os
        responsáveis pelas áreas pertinentes, agendar visitas, perguntar sobre
        eventos e tudo mais! :)
      </p>

      {fields.map((item, index) => {
        return (
          <div className="row contact" key={index}>
            {index > 0 && <div className="line" />}
            <div className="col-md-6">
              <h3>NOME</h3>
              <Controller
                render={({ field }) => (
                  <Input
                    type="text"
                    className="input-oqfc"
                    placeholder="Com quem falamos?"
                    {...field}
                  />
                )}
                // @ts-ignore
                defaultValue={item.name}
                name={`address[${addressIndex}].${prefix}[${index}].name`}
                control={control}
              />
            </div>

            <div className="col-md-6">
              <h3>TELEFONE</h3>
              <InputMask
                className="input-oqfc"
                mask="phone"
                placeholder="Whatsapp de contato"
                form={register(
                  `address[${addressIndex}].${prefix}[${index}].phone`
                )}
              />
            </div>

            <div className="col-md-6">
              <h3>EMAIL</h3>
              <Controller
                render={({ field, formState }) => (
                  <Input
                    type="text"
                    className="input-oqfc"
                    placeholder="O melhor e-mail"
                    // @ts-ignore
                    error={getErrorMessage(formState.errors, index, "email")}
                    {...field}
                  />
                )}
                // @ts-ignore
                defaultValue={item.email}
                name={`address[${addressIndex}].${prefix}[${index}].email`}
                control={control}
              />
            </div>

            <div className="col-md-6 input-select">
              <h3>SETOR</h3>
              <Controller
                control={control}
                name={`address[${addressIndex}].${prefix}[${index}].contactType`}
                render={({ field, formState: { errors } }) => (
                  <InputSelect
                    {...field}
                    //  error={errors.address?.value?.message}
                    className="input-oqfc-select"
                    placeholder="Setor"
                    options={options}
                  />
                )}
              />
            </div>

            <div className="col-md-12">
              {index > 0 && (
                <button
                  className="delUnidade"
                  onClick={() => remove(index)}
                  type="button"
                >
                  Excluir este contato
                </button>
              )}
            </div>
          </div>
        );
      })}

      <div className="col-12">
        <button className="addHorarios" type="button" onClick={handleAdd}>
          ADICIONAR MAIS UM CONTATO
        </button>
      </div>
    </div>
  );
}
