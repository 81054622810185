import { UseFormRegisterReturn } from "react-hook-form";

export type Props = {
  className?: string;
  name?: string;
  placeholder?: string;
  id?: string;
  rows?: number;
  label?: string;
  caption?: string;
  disabled?: boolean;
  size?: "large" | "small";
  type?: string;
  form?: Partial<UseFormRegisterReturn>;
  error?: string;
};

export function Input({ error, form, caption, size, ...props }: Props) {
  return (
    <div className="oqfc-input-container">
      <input
        placeholder={props.placeholder}
        className={props.className}
        type={props.type}
        disabled={props.disabled}
        {...props}
        {...form}
      />
      {<span className="text-danger">{error}</span>}
    </div>
  );
}
