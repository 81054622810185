import { FieldValues, UseFormSetError } from "react-hook-form";

import { handleErrorForm } from "services/api";
import { ShoppingCenter } from "interfaces/ShoppingCenter";

import {
  ShoppingCenterFilters,
  create,
  list,
  listAll,
  remove,
  retrieve,
  update,
  updateOrders,
} from "./calls";

export * from "./calls";

export default class ShoppingCenterAPICaller {
  static adaptFromAPI = (data: ShoppingCenter) => data as FieldValues;
  static adaptToAPI = (data: FieldValues) => data as ShoppingCenter;

  static list = async (filters: ShoppingCenterFilters) => {
    const {
      data: { shoppingCenters },
    } = await list(filters);

    return {
      ...shoppingCenters,
      result: shoppingCenters.result.map(this.adaptFromAPI),
    };
  };

  static all = async () => {
    const { data } = await listAll();
    return data.shoppingCenters;
  };

  static fetch = async (editId: string) => {
    const { data } = await retrieve(editId);
    return this.adaptFromAPI(data.shoppingCenter);
  };

  static createOrUpdate = async (
    data: FieldValues,
    setError: UseFormSetError<FieldValues>
  ) => {
    const method = data.id ? update : create;

    const result = method(this.adaptToAPI(data)).catch(
      handleErrorForm(setError)
    );

    return result;
  };

  static updateOrders = async (ids: string[]) => {
    return await updateOrders(ids);
  };

  static remove = async (id: string) => {
    const { data } = await remove(id);

    return data;
  };
}
