import { FieldValues, UseFormSetError } from "react-hook-form";

import {
  Establishment,
  EstablishmentStatus,
  EstablishmentType,
} from "interfaces/Establishment";
import { EstablishmentUnit } from "interfaces/EstablishmentUnit";
import { Voucher } from "interfaces/Voucher";
import { handleErrorForm } from "services/api";
import { getArrayDifferences } from "utils/array";
import { reverseKeyValueObject } from "utils/object";

import EstablishmentUnitAPICaller from "../establishmentUnit";
import UsersAPICaller from "../users";
import VoucherAPICaller from "../voucher";

import {
  EstablishmentFilters,
  create,
  list,
  remove,
  retrieve,
  update,
} from "./calls";

export * from "./calls";

export default class EstablishmentAPICaller {
  static adaptFromAPI = async (data: Establishment) => {
    const establishment = {
      ...data,
    };

    establishment.status =
      EstablishmentStatus[data.status as keyof typeof EstablishmentStatus];

    establishment.type =
      EstablishmentType[data.type as keyof typeof EstablishmentType];

    establishment.logo = data.logoUrl!;
    establishment.image = data.imageUrl!;

    establishment.units = data.units?.map(
      EstablishmentUnitAPICaller.adaptFromAPI
    ) as EstablishmentUnit[];

    if (data.vouchers) {
      establishment.vouchers = (await Promise.all(
        data.vouchers.map((item: Voucher) => {
          const adaptedItem = VoucherAPICaller.adaptFromAPI(item);
          return adaptedItem;
        })
      )) as Voucher[];
    }

    return establishment as FieldValues;
  };

  static adaptToAPI = (data: FieldValues) => {
    const establishment = {
      ...data,
    } as FieldValues;

    establishment.status =
      reverseKeyValueObject(EstablishmentStatus)[data.status.value];

    establishment.type =
      reverseKeyValueObject(EstablishmentType)[data.type.value];

    return establishment as Establishment;
  };

  static list = async <T extends any = any>(filters: EstablishmentFilters) => {
    const {
      data: { establishments },
    } = await list<{ establishments: { result: Array<any>; total: number } }>(
      filters
    );

    return {
      ...establishments,
      result: await Promise.all(
        establishments.result.map((item: Establishment) => {
          const adaptedItem = this.adaptFromAPI(item) as T;
          return adaptedItem;
        })
      ),
    };
  };

  static fetch = async <T extends FieldValues = FieldValues>(
    editId: string,
    params?: EstablishmentFilters
  ) => {
    const {
      data: { establishment },
    } = await retrieve(editId, params);

    return (await this.adaptFromAPI(establishment)) as T;
  };

  static createOrUpdate = async <T extends FieldValues>(
    data: T,
    setError: UseFormSetError<T>
  ) => {
    const method = data.id ? update : create;

    const result = await method<{ establishment: any }>(
      this.adaptToAPI(data)
    ).catch(handleErrorForm(setError));

    return result;
  };

  static updateUsersAccess = async <T extends FieldValues>(
    establishmentId: string,
    originalUsers: Array<string>,
    updatedUsers: Array<string>,
    setError: UseFormSetError<T>
  ) => {
    const { itemsRemoved, itemsAdded } = getArrayDifferences(
      originalUsers,
      updatedUsers,
      false
    );

    const removeEstablishmentsPromises = itemsRemoved.map((userId) =>
      UsersAPICaller.removeEstablishments(userId, [establishmentId], () => {
        setError("usersWithAccess" as Parameters<typeof setError>[0], {
          message: "Problema removendo o estabelecimento a um usuário",
        });
      })
    );

    const removeResponses = await Promise.all(removeEstablishmentsPromises);

    const addEstablishmentsPromises = itemsAdded.map((userId) =>
      UsersAPICaller.addEstablishments(userId, [establishmentId], () => {
        setError("usersWithAccess" as Parameters<typeof setError>[0], {
          message: "Problema adicionando o estabelecimento a um usuário",
        });
      })
    );

    const addResponses = await Promise.all(addEstablishmentsPromises);

    const responses = [...removeResponses, ...addResponses];

    const errors: Array<{}> = [];

    responses.forEach((response) => {
      response.forEach(({ data }) => {
        if (data && data.errors) {
          errors.push(data.errors);
        }
      });
    });

    return { errors };
  };

  static remove = async (id: string) => {
    const { data } = await remove(id);

    return data;
  };
}
