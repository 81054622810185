import { FieldValues, UseFormSetError } from "react-hook-form";

import {
  EstablishmentUnit,
  EstablishmentUnitContactType,
  EstablishmentUnitStatus,
  WeekDays,
} from "interfaces/EstablishmentUnit";
import { handleErrorForm } from "services/api";
import { reverseKeyValueObject, toValueLabel } from "utils/object";
import { formatPhone } from "utils/string/phone";
import { getArrayDifferences } from "utils/array";
import { ShoppingCenter } from "interfaces/ShoppingCenter";

import UsersAPICaller from "../users";

import {
  EstablishmentUnitFilters,
  create,
  list,
  remove,
  retrieve,
  update,
} from "./calls";

export * from "./calls";

type OpenTimes = {
  start: string;
  end: string;
  monday: boolean;
  tuesday: boolean;
  wednesday: boolean;
  thursday: boolean;
  friday: boolean;
  saturday: boolean;
  sunday: boolean;
  holiday: boolean;
};

export default class EstablishmentUnitAPICaller {
  static adaptFromAPI = (data: EstablishmentUnit) => {
    const unit = {
      ...data,
    };

    unit.status =
      EstablishmentUnitStatus[
        data.status as keyof typeof EstablishmentUnitStatus
      ];

    unit.openTimes = data.openTimes?.map((it) => {
      const week: Record<string, boolean> = {};

      it.days.forEach((day) => {
        week[day] = true;
      });

      return {
        ...it,
        ...week,
      };
    });

    unit.contacts = data.contacts?.map((it) => {
      return {
        ...it,
        phone: formatPhone(it.phone || ""),
        contactType: it.contactType,
      };
    });

    if (data.shoppingCenter) {
      unit.shoppingCenter = {
        value: (data.shoppingCenter as ShoppingCenter).id!,
        label: (data.shoppingCenter as ShoppingCenter).name,
      };
    }

    return unit as FieldValues;
  };

  static adaptToAPI = (data: FieldValues) => {
    const unit = {
      ...data,
    };

    unit.status = reverseKeyValueObject(EstablishmentUnitStatus)[
      data.status.value || data.status
    ];

    unit.state = data.state.value;
    unit.city = data.city.value;
    unit.district = data.district.value;
    const openTimes: { start: string; end: string; days: string[] }[] = [];
    const contacts: {
      contactType: string;
      email: string;
      name: string;
      phone: string;
    }[] = [];

    data.openTimes.forEach((item: OpenTimes) => {
      const days: string[] = Object.keys(item).filter(
        (day) =>
          Object.keys(WeekDays).includes(day) &&
          item[day as keyof typeof WeekDays]
      );

      openTimes.push({
        start: item.start,
        end: item.end,
        days: days,
      });
    });

    data.contacts?.forEach(
      (item: {
        contactType: { value: string };
        phone: string;
        email: string;
        name: string;
      }) => {
        if (item.contactType?.value && item.name) {
          contacts.push({
            ...item,
            phone: item.phone?.replace(/\D/g, ""),
            contactType: reverseKeyValueObject(EstablishmentUnitContactType)[
              item.contactType?.value
            ],
          });
        }
      }
    );

    unit.openTimes = openTimes;
    unit.contacts = contacts;
    unit.shoppingCenterId = unit.shoppingCenter?.value
      ? unit.shoppingCenter.value
      : null;
    delete unit.shoppingCenter;

    return unit as EstablishmentUnit;
  };

  static list = async (filters: EstablishmentUnitFilters) => {
    const {
      data: { units },
    } = await list(filters);

    return {
      ...units,
      result: units.result.map(this.adaptFromAPI),
    };
  };

  static fetch = async <T extends FieldValues = FieldValues>(
    editId: string
  ) => {
    const {
      data: { unit },
    } = await retrieve(editId);

    return this.adaptFromAPI(unit) as T;
  };

  static createOrUpdate = async <T extends FieldValues = {}>(
    data: T,
    setError: UseFormSetError<T>
  ) => {
    const method = data.id ? update : create;

    const result = method<{ unit: any }>(this.adaptToAPI(data)).catch(
      handleErrorForm(setError)
    );

    return result;
  };

  static updateUsersAccess = async <T extends FieldValues>(
    establishmentUnitId: string,
    originalUsers: Array<string>,
    updatedUsers: Array<string>,
    setError: UseFormSetError<T>
  ) => {
    const { itemsRemoved, itemsAdded } = getArrayDifferences(
      originalUsers,
      updatedUsers,
      false
    );

    const removeEstablishmentUnitsPromises = itemsRemoved.map((userId) =>
      UsersAPICaller.removeUnits(userId, [establishmentUnitId], () => {
        setError("usersWithAccess" as Parameters<typeof setError>[0], {
          message: "Problema removendo a unidade a um usuário",
        });
      })
    );

    const removeResponses = await Promise.all(removeEstablishmentUnitsPromises);

    const addEstablishmentUnitsPromises = itemsAdded.map((userId) =>
      UsersAPICaller.addUnits(userId, [establishmentUnitId], () => {
        setError("usersWithAccess" as Parameters<typeof setError>[0], {
          message: "Problema adicionando a unidade a um usuário",
        });
      })
    );

    const addResponses = await Promise.all(addEstablishmentUnitsPromises);

    const responses = [...removeResponses, ...addResponses];

    const errors: Array<{}> = [];

    responses.forEach((response) => {
      response.forEach(({ data }) => {
        if (data && data.errors) {
          errors.push(data.errors);
        }
      });
    });

    return { errors };
  };

  static remove = async (id: string) => {
    const { data } = await remove(id);

    return data;
  };
}
