import { configureStore } from "@reduxjs/toolkit";

import voucherReducer from "./slice/voucher";
import optionReducer from "./slice/option";

export const store = configureStore({
  reducer: {
    voucher: voucherReducer,
    options: optionReducer,
  },
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
