import Skeleton from "react-loading-skeleton";

interface Props<T> {
  data: T | null;
  isLoading: boolean;
  height?: number;
  className?: string;
}

export default function DataLoader<T>({
  isLoading,
  data,
  height,
  className,
}: Props<T>) {
  if (isLoading)
    return (
      <Skeleton
        style={{ width: "98%" }}
        height={height}
        className={className}
      />
    );

  return <>{data}</>;
}
