import { Benefit } from "interfaces/Benefit";

import { RepositoryParams, api } from "..";

export type BenefitFilters = Partial<Benefit & RepositoryParams>;

export const list = async (params: BenefitFilters) => {
  return api.get("/benefits", { params });
};
export const listAll = async () => {
  return api.get("/benefits/all");
};

export const create = async (params: Benefit) => {
  return api.post("/benefits", { ...params });
};

export const update = async (params: Benefit) => {
  return api.patch(`/benefits/${params.id}`, { ...params });
};

export const retrieve = async (id: string) => {
  return api.get(`/benefits/${id}`);
};

export const remove = async (id: string) => {
  return api.delete(`/benefits/${id}`);
};
