import { useForm, FormProvider, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import { schema } from "./validate";
import { InputMask } from "components/InputMask";

import { load } from "store/slice/option";
import { search } from "store/slice/voucher";

import { useSelector, useDispatch } from "react-redux";
import { RootState } from "store/index";
import { Loading } from "components/Loading";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";

export function Search() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { isLoading } = useSelector((state: RootState) => state.voucher);

  const projectName = process.env.REACT_APP_PROJECT_NAME;
  const projectYear = process.env.REACT_APP_YEAR;

  const methods = useForm({
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    // @ts-ignore
    dispatch(load());
  }, []);

  const onSubmitHandler = async (data: any) => {
    // @ts-ignore
    await dispatch(search(data));

    navigate("/voucher");
  };

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmitHandler)} role="account">
        <div className="offset-xl-1 d-flex justify-content-center">
          <div id="cadastro" className="col-11 col-xl-6 ">
            <div className="col-12 align-items-start">
              <h2>Cadastro Estabelecimento Tour {projectYear}</h2>
              <p>
                <strong>
                  <i>
                    Seu estabelecimento foi selecionado para participar da nova
                    edi&ccedil;&atilde;o do {projectName}!
                  </i>
                </strong>
                <br />
                Preencha as informa&ccedil;&otilde;es abaixo com o m&aacute;ximo
                de aten&ccedil;&atilde;o para que possamos dar sequ&ecirc;ncia
                no contrato e confec&ccedil;&atilde;o do voucher para
                aprova&ccedil;&atilde;o.
              </p>

              <div className="justify-content-center justify-content-lg-between row ">
                <div className="col-md-6 flex-wrap justify-content-middle">
                  <h3>
                    CNPJ <span className="asterisco">*</span>
                  </h3>

                  <InputMask
                    className="input-oqfc"
                    mask="cnpj"
                    placeholder="Preencha somente n&uacute;meros"
                    form={methods.register("cnpj")}
                    error={methods.formState.errors?.cnpj?.message}
                  />
                </div>

                <div className="col-6 d-flex flex-wrap align-middle">
                  <button type="submit" className="btnEnviar btnSearch">
                    Continuar
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
      {isLoading && <Loading />}
    </FormProvider>
  );
}
