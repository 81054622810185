import { useFormContext, useFieldArray } from "react-hook-form";
import { InputMask } from "..";

interface Props {
  addressIndex: number;
}

export function OpenTimes({ addressIndex }: Props) {
  const prefix = "openTimes";
  const {
    control,
    register,
    formState: { errors },
  } = useFormContext();
  const { fields, append, remove } = useFieldArray({
    control,
    name: `address[${addressIndex}].${prefix}`,
  });

  function handleAdd() {
    append({
      start: "",
      end: "",
      days: [],
    });
  }

  function getErrorMessage(errors: any, index: number, fieldName: string) {
    if (
      errors?.address &&
      errors?.address[addressIndex] &&
      errors?.address[addressIndex]?.openTimes &&
      errors?.address[addressIndex]?.openTimes[index]
    ) {
      if (errors?.address[addressIndex]?.openTimes[index]?.[fieldName]?.message)
        return errors?.address[addressIndex]?.openTimes[index]?.[fieldName]
          ?.message;
    }
    return "";
  }

  return (
    <>
      {fields.map((item, index) => {
        return (
          <div className="row col-12 d-flex" key={index}>
            <div className="row col-12 d-flex">
              <div className="col-md-6">
                <h3>In&iacute;cio</h3>

                <InputMask
                  type="text"
                  mask="hour"
                  className="input-oqfc"
                  placeholder="00:00"
                  form={register(
                    `address[${addressIndex}].${prefix}[${index}].start`
                  )}
                  error={getErrorMessage(errors, index, "start")}
                />
              </div>
              <div className="col-md-6">
                <h3>Fim</h3>
                <InputMask
                  type="text"
                  mask="hour"
                  className="input-oqfc"
                  placeholder="00:00"
                  form={register(
                    `address[${addressIndex}].${prefix}[${index}].end`
                  )}
                  error={getErrorMessage(errors, index, "end")}
                />
              </div>
            </div>

            <div className="col-12 d-flex flex-wrap justify-content-center justify-content-xl-start row">
              <div className="col-6">
                <input
                  id={`address[${addressIndex}].${prefix}[${index}].monday`}
                  type="checkbox"
                  value="monday"
                  {...register(
                    `address[${addressIndex}].${prefix}[${index}].days`
                  )}
                />
                <label
                  htmlFor={`address[${addressIndex}].${prefix}[${index}].monday`}
                >
                  &nbsp;&nbsp;Segunda
                </label>
                <br />

                <input
                  id={`address[${addressIndex}].${prefix}[${index}].tuesday`}
                  type="checkbox"
                  value="tuesday"
                  {...register(
                    `address[${addressIndex}].${prefix}[${index}].days`
                  )}
                />
                <label
                  htmlFor={`address[${addressIndex}].${prefix}[${index}].tuesday`}
                >
                  &nbsp;&nbsp;Ter&ccedil;a
                </label>
                <br />

                <input
                  id={`address[${addressIndex}].${prefix}[${index}].wednesday`}
                  type="checkbox"
                  value="wednesday"
                  {...register(
                    `address[${addressIndex}].${prefix}[${index}].days`
                  )}
                />
                <label
                  htmlFor={`address[${addressIndex}].${prefix}[${index}].wednesday`}
                >
                  &nbsp;&nbsp;Quarta
                </label>
                <br />

                <input
                  id={`address[${addressIndex}].${prefix}[${index}].thursday`}
                  type="checkbox"
                  value="thursday"
                  {...register(
                    `address[${addressIndex}].${prefix}[${index}].days`
                  )}
                />
                <label
                  htmlFor={`address[${addressIndex}].${prefix}[${index}].thursday`}
                >
                  &nbsp;&nbsp;Quinta
                </label>
                <br />
              </div>
              <div className="col-6">
                <input
                  id={`address[${addressIndex}].${prefix}[${index}].friday`}
                  type="checkbox"
                  value="friday"
                  {...register(
                    `address[${addressIndex}].${prefix}[${index}].days`
                  )}
                />
                <label
                  htmlFor={`address[${addressIndex}].${prefix}[${index}].friday`}
                >
                  &nbsp;&nbsp;Sexta
                </label>
                <br />
                <input
                  id={`address[${addressIndex}].${prefix}[${index}].saturday`}
                  type="checkbox"
                  value="saturday"
                  {...register(
                    `address[${addressIndex}].${prefix}[${index}].days`
                  )}
                />
                <label
                  htmlFor={`address[${addressIndex}].${prefix}[${index}].saturday`}
                >
                  &nbsp;&nbsp;S&aacute;bado
                </label>
                <br />
                <input
                  id={`address[${addressIndex}].${prefix}[${index}].sunday`}
                  type="checkbox"
                  value="sunday"
                  {...register(
                    `address[${addressIndex}].${prefix}[${index}].days`
                  )}
                />
                <label
                  htmlFor={`address[${addressIndex}].${prefix}[${index}].sunday`}
                >
                  &nbsp;&nbsp;Domingo
                </label>
                <br />

                <input
                  id={`address[${addressIndex}].${prefix}[${index}].holiday`}
                  type="checkbox"
                  value="holiday"
                  {...register(
                    `address[${addressIndex}].${prefix}[${index}].days`
                  )}
                />
                <label
                  htmlFor={`address[${addressIndex}].${prefix}[${index}].holiday`}
                >
                  &nbsp;&nbsp;Feriados
                </label>
                <br />
              </div>
              {getErrorMessage(errors, index, "days") && (
                <span className="text-danger">
                  {getErrorMessage(errors, index, "days")}
                </span>
              )}

              {index > 0 && (
                <div className="col-12 ">
                  <button
                    type="button"
                    className="addHorarios"
                    onClick={() => remove(index)}
                  >
                    Excluir hor&aacute;rio
                  </button>
                </div>
              )}
            </div>
          </div>
        );
      })}

      <div className="col-12 d-flex flex-wrap justify-content-center">
        <button
          type="button"
          className="addBeneficioHorarios"
          onClick={handleAdd}
        >
          NOVO HOR&Aacute;RIO
        </button>
      </div>
    </>
  );
}
