import { FieldValues, UseFormSetError } from "react-hook-form";

import { handleErrorForm } from "services/api";
import { Convenience } from "interfaces/Convenience";

import {
  ConvenienceFilters,
  create,
  list,
  listAll,
  remove,
  retrieve,
  update,
  updateOrders,
} from "./calls";

export * from "./calls";

export default class ConvenienceAPICaller {
  static adaptFromAPI = (data: Convenience) => data as FieldValues;
  static adaptToAPI = (data: FieldValues) => data as Convenience;

  static list = async (filters: ConvenienceFilters) => {
    const {
      data: { conveniences },
    } = await list(filters);

    return {
      ...conveniences,
      result: conveniences.result.map(this.adaptFromAPI),
    };
  };

  static all = async () => {
    const { data } = await listAll();
    return data.conveniences;
  };

  static fetch = async (editId: string) => {
    const { data } = await retrieve(editId);
    return this.adaptFromAPI(data.convenience);
  };

  static createOrUpdate = async (
    data: FieldValues,
    setError: UseFormSetError<FieldValues>
  ) => {
    const method = data.id ? update : create;

    const result = method(this.adaptToAPI(data)).catch(
      handleErrorForm(setError)
    );

    return result;
  };

  static updateOrders = async (ids: string[]) => {
    return await updateOrders(ids);
  };

  static remove = async (id: string) => {
    const { data } = await remove(id);

    return data;
  };
}
