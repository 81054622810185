import { PropsWithChildren, useId } from "react";

import ReactModal from "react-modal";

type Props = PropsWithChildren<{
  className?: string;
  isOpen: boolean;
  onClickAway: () => void;
  size: "small" | "medium" | "large";
}>;

export default function Modal(props: Props) {
  const id = useId();
  const modalClassName = ["modal-box"];
  if (props.className) modalClassName.push(props.className);
  modalClassName.push(`modal-size-${props.size}`);

  return (
    <ReactModal isOpen={props.isOpen} className="modal-overlay">
      <div
        id={`modal-${id}`}
        onMouseDown={(e) => {
          (e.target as Element)?.id === `modal-${id}` && props.onClickAway();
        }}
      >
        <div
          onClick={(e) => e.stopPropagation()}
          className={modalClassName.join(" ")}
        >
          <div className="modal-close">
            <div
              className="btn-close-modal"
              data-dismiss="modal"
              aria-label="Close"
              onClick={props.onClickAway}
            >
              <span aria-hidden="true">&times;</span>
            </div>
          </div>
          {props.children}
        </div>
      </div>
    </ReactModal>
  );
}

Modal.defaultProps = {
  size: "medium",
};
