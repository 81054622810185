import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { Establishment } from "interfaces/Establishment";
import { EstablishmentUnit } from "interfaces/EstablishmentUnit";
import { Voucher } from "interfaces/Voucher";

import { login, setAuthorization } from "services/api";
import EstablishmentAPICaller from "services/api/establishment";
import EstablishmentUnitAPICaller from "services/api/establishmentUnit";
import PlanAPICaller from "services/api/plans";
import VoucherAPICaller from "services/api/voucher";

export interface VoucherState {
  isLoading: boolean;

  data?: {
    establishment?: Establishment;
    units?: EstablishmentUnit[];
    voucher?: Voucher | undefined;
    planId: string;
    cnpj: string;
  };
  isError: boolean;
  started: boolean;
}

const initialState: VoucherState = {
  isLoading: false,
  data: {
    planId: "",
    cnpj: "",
  },
  isError: false,
  started: false,
};

export const search = createAsyncThunk(
  "search",
  async (data: Partial<Establishment>) => {
    const {
      data: { token },
    } = await login(
      process.env.REACT_APP_USERNAME!,
      process.env.REACT_APP_PASSWORD!
    );

    setAuthorization(token);
    const [establishmentList, plan] = await Promise.all([
      EstablishmentAPICaller.list(data),
      PlanAPICaller.list({ active: true, activeSale: true }),
    ]);

    const planId = plan.result[0].id;
    if (establishmentList.result.length) {
      const establishment: Establishment = await EstablishmentAPICaller.fetch(
        establishmentList.result[0].id
      );

      const vouchers = establishment?.vouchers ?? [];
      const currentVoucher = vouchers.find(
        (voucher: Voucher) => voucher?.plan?.id === planId
      );

      const requests = [
        Promise.all(
          // @ts-ignore
          establishment?.units?.map(({ id }) =>
            EstablishmentUnitAPICaller.fetch(id!)
          )
        ),
      ];

      if (currentVoucher?.id) {
        // @ts-ignore
        requests.push(VoucherAPICaller.fetch(currentVoucher?.id!));
      } else {
        requests.push(
          new Promise((resolve) => {
            resolve({
              // @ts-ignore
              usableTimes: [
                {
                  start: "",
                  end: "",
                  days: [],
                },
              ],
              substitutions: [],
            });
          })
        );
      }

      const [units, voucher] = await Promise.all(requests);

      return {
        planId,
        establishment: establishment as Establishment,
        // @ts-ignore
        voucher: voucher as Voucher,
        units: units as EstablishmentUnit[],
        cnpj: data.cnpj ?? "",
      };
    }

    return { ...initialState.data, planId, cnpj: data.cnpj ?? "" };
  }
);

export const voucherSlice = createSlice({
  name: "voucher",
  initialState,
  reducers: {
    reset: (state) => {
      state = initialState;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(search.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(search.fulfilled, (state, action) => {
      state.isLoading = false;
      state.started = true;
      state.data = action.payload;
    });
    builder.addCase(search.rejected, (state, action) => {
      state.isError = true;
    });
  },
});

export const { reset } = voucherSlice.actions;

export default voucherSlice.reducer;
