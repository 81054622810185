import { ShoppingCenter } from "interfaces/ShoppingCenter";

import { RepositoryParams, api } from "..";

export type ShoppingCenterFilters = Partial<ShoppingCenter & RepositoryParams>;

export const list = async (params: ShoppingCenterFilters) => {
  return api.get("/shopping_centers", { params });
};

export const listAll = async () => {
  return api.get("/shopping_centers/all");
};

export const create = async (params: ShoppingCenter) => {
  return api.post("/shopping_centers", { ...params });
};

export const update = async (params: ShoppingCenter) => {
  return api.patch(`/shopping_centers/${params.id}`, { ...params });
};

export const updateOrders = async (ids: string[]) => {
  return api.put("/shopping_centers/order", { newOrder: ids });
};

export const retrieve = async (id: string) => {
  return api.get(`/shopping_centers/${id}`);
};

export const remove = async (id: string) => {
  return api.delete(`/shopping_centers/${id}`);
};
