import React from "react";
import "./css/bootstrap.min.css";
import "./css/style.css";

// import Aquario from "./features/Aquario";
import { useSelector } from "react-redux";
import { RootState } from "store/index";

import { Routes, Route, Outlet, Navigate } from "react-router-dom";

import { Search } from "views/search";
import { Voucher } from "views/voucher";

import useScript from "hooks/useScript";

interface ProtectRouteProps {
  redirectPath?: string;
}

const ProtectedRoute = ({ redirectPath = "/" }: ProtectRouteProps) => {
  const started = useSelector((state: RootState) => state.voucher.started);

  return started ? <Outlet /> : <Navigate to={redirectPath} />;
};

function App() {
  useScript(
    `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_MAPS_KEY}&libraries=places`
  );

  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<Search />} />
        <Route path="/voucher" element={<ProtectedRoute redirectPath="/" />}>
          <Route path="/voucher" element={<Voucher />} />
        </Route>
      </Routes>
    </div>
  );
}

export default App;
