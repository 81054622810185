import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { Option } from "components/InputSelect";
import { Benefit } from "interfaces/Benefit";
import { ShoppingCenter } from "interfaces/ShoppingCenter";

import { login, setAuthorization } from "services/api";
import BenefitAPICaller from "services/api/benefits";
import PlanAPICaller from "services/api/plans";
import ShoppingCenterAPICaller from "services/api/shoppingCenters";
import SubstitutionsAPICaller from "services/api/substitutions";

import { SelectOptions } from "interfaces/Utils";
import StatesAPICaller from "services/api/states";
import ConvenienceAPICaller from "services/api/conveniences";
import { Convenience } from "interfaces/Convenience";
import { Substitution } from "interfaces/Substitution";

const takeawayId = process.env.REACT_APP_TAKEAWAY_ID;
const localConsumeId = process.env.REACT_APP_LOCAL_CONSUME_ID;

export interface OptionState {
  isLoading: boolean;
  data: {
    benefits: Option[];
    allConveniences: {
      [key: string]: string;
    };
    conveniences: Option[];
    shoppings: Option[];
    states: (SelectOptions & { abbreviation: string; name: string })[];
    substitutions: Option[];
    planId: String;
  };
  isError: boolean;
}

const initialState: OptionState = {
  isLoading: false,
  data: {
    benefits: [],
    allConveniences: {},
    conveniences: [],
    shoppings: [],
    states: [],
    substitutions: [],
    planId: "",
  },
  isError: false,
};

export const load = createAsyncThunk("load", async () => {
  const {
    data: { token },
  } = await login(
    process.env.REACT_APP_USERNAME!,
    process.env.REACT_APP_PASSWORD!
  );

  setAuthorization(token);

  const [benefits, shoppings, convenienceData, planId, states, substitutions] =
    await Promise.all([
      BenefitAPICaller.all().then((data) =>
        data.map(({ id, name }: Benefit) => ({
          value: id,
          label: name,
        }))
      ),
      ShoppingCenterAPICaller.all().then((data) =>
        data.map(({ id, name }: ShoppingCenter) => ({
          value: id,
          label: name,
        }))
      ),
      ConvenienceAPICaller.all().then((data) => {
        const allConveniences = Object.fromEntries(
          // @ts-ignore
          Object.entries(data).map(([_, v]) => [v.id, v.name])
        );

        const conveniences = data
          .filter(
            ({ id }: Convenience) => id !== takeawayId && id !== localConsumeId
          )
          .map(({ id, name }: Convenience) => ({
            value: id,
            label: name,
          }));

        return { allConveniences, conveniences };
      }),
      PlanAPICaller.list({ active: true, activeSale: true }).then(
        (data) => data.result[0].id
      ),
      StatesAPICaller.fetchStates().then((statesResponse) => statesResponse),
      SubstitutionsAPICaller.all().then((data) =>
        data.map(({ id, name }: Substitution) => ({
          value: id,
          label: name,
        }))
      ),
    ]);

  const { conveniences, allConveniences } = convenienceData;
  // @ts-ignore
  return {
    benefits,
    planId,
    states,
    shoppings,
    allConveniences,
    conveniences,
    substitutions,
  } as OptionState;
});

export const optionReducer = createSlice({
  name: "option",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(load.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(load.fulfilled, (state, action) => {
      state.isLoading = false;
      // @ts-ignore
      state.data = action.payload;
    });
    builder.addCase(load.rejected, (state, action) => {
      state.isError = true;
    });
  },
});

export default optionReducer.reducer;
