import Select, { GroupBase, Props } from "react-select";

export interface Option {
  value: string;
  label: string;
}

type SelectProps<
  Option,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>
> = Props<Option, IsMulti, Group> & {
  error?: string;
};

export function InputSelect({ error, ...props }: SelectProps<Option>) {
  // @ts-ignore
  return (
    <div className="oqfc-input-container">
      <Select {...props} />
      {<span className="text-danger">{error}</span>}
    </div>
  );
}
