import React from "react";

interface Props {
  title: string;
  message: string | React.ReactElement;
  className: string;
  handleClose?(): void;
}

export function Message({ title, message, className, handleClose }: Props) {
  return (
    <div
      id="modal-error"
      className="modal-error flex-wrap justify-content-center align-content-center d-flex"
    >
      <div className="close-modal"></div>
      <div
        className={`col-11 col-md-8 col-lg-6 col-xl-4 col-xxl-3 box-crop relative alert ${className}`}
      >
        <div className="infos-error">
          <h5 id="messageTitle">{title}</h5>
          <p id="messageMessage">{message}</p>
          <button
            type="button"
            id="modal-error-botao"
            className={`btn-modal-error ${
              className === "alert-success" ? "btn-modal-success" : ""
            }`}
            onClick={handleClose}
          >
            {className === "alert-success" ? "OK" : "Corrigir"}
          </button>
        </div>
      </div>
    </div>
  );
}
