import { Establishment } from "interfaces/Establishment";
import { objectToFormData } from "services/io/file";

import { RepositoryParams, api } from "..";

export type EstablishmentFilters = Partial<Establishment & RepositoryParams>;
type CreateOrUpdateEstablishmentData = Omit<Establishment, "usersWithAccess">;

export const list = async <T = {}>(params: EstablishmentFilters) => {
  return api.get<T>("/establishments", { params });
};

export const create = async <T = {}>(
  params: CreateOrUpdateEstablishmentData
) => {
  const payload = {
    logo: params.logo,
    image: params.image,
    payload: JSON.stringify(params),
  };

  return api.post<T>("/establishments", objectToFormData(payload), {
    headers: { "Content-Type": "multipart/form-data" },
  });
};

export const update = async <T = {}>(
  params: CreateOrUpdateEstablishmentData
) => {
  const payload = {
    logo: params.logo,
    image: params.image,
    payload: JSON.stringify(params),
  };

  return api.patch<T>(
    `/establishments/${params.id}`,
    objectToFormData(payload),
    {
      headers: { "Content-Type": "multipart/form-data" },
    }
  );
};

export const retrieve = async (id: string, params?: EstablishmentFilters) => {
  return api.get(`/establishments/${id}`, { params });
};

export const remove = async (id: string) => {
  return api.delete(`/establishments/${id}`);
};
