import { EstablishmentUnit } from "interfaces/EstablishmentUnit";

import { RepositoryParams, api } from "..";

export type EstablishmentUnitFilters = Partial<
  EstablishmentUnit & RepositoryParams
>;

type CreateOrUpdateEstablishmentUnitData = Omit<
  EstablishmentUnit,
  "usersWithAccess"
>;

export const list = async (params: EstablishmentUnitFilters) => {
  return api.get("/establishments/units", { params });
};

export const create = async <T = {}>(
  params: CreateOrUpdateEstablishmentUnitData
) => {
  return api.post<T>("/establishments/units", { ...params });
};

export const update = async <T = {}>(
  params: CreateOrUpdateEstablishmentUnitData
) => {
  return api.patch<T>(`/establishments/units/${params.id}`, { ...params });
};

export const retrieve = async (id: string) => {
  return api.get(`/establishments/units/${id}`);
};

export const remove = async (id: string) => {
  return api.delete(`/establishments/units/${id}`);
};
