export const onlyNumber = (number: string | number) => {
  if (typeof number === "number") {
    return number;
  }
  return number?.replace(/[^\d]+/g, "");
};

export const removeDuplicates = (data: string[]) => {
  return data.filter((value, index) => data.indexOf(value) === index);
};
