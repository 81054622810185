import { User } from "interfaces/User";

import { RepositoryParams, api } from "../";

export type UserFilters = Partial<User & RepositoryParams>;

export const list = async <T>(params: UserFilters) => {
  return api.get<T>("/users", { params });
};

export const create = async (params: User) => {
  return api.post("/users", { ...params });
};

export const update = async (params: User) => {
  return api.patch(`/users/${params.id}`, { ...params });
};

export const updatePassword = async (
  id: string,
  { password }: { password: string }
) => {
  return api.put(`/users/${id}/password`, { password });
};

export const retrieve = async <T>(id: string) => {
  return api.get<T>(`/users/${id}`);
};

export const setAdmin = async (
  userId: string,
  params?: { admin?: boolean }
) => {
  return api.post(`/users/${userId}/admin`, params);
};

export const setGroup = async (userId: string, groupId: string) => {
  return api.post(`/users/${userId}/groups/${groupId}`);
};

export const removeGroup = async (userId: string) => {
  return api.delete(`/users/${userId}/groups`);
};

export const addEstablishment = <T>(
  userId: string,
  establishmentId: string
) => {
  return api.put<T>(`/users/${userId}/establishments/${establishmentId}`);
};

export const removeEstablishment = (
  userId: string,
  establishmentId: string
) => {
  return api.delete(`/users/${userId}/establishments/${establishmentId}`);
};

export const addUnit = (userId: string, unitsId: string) => {
  return api.put(`/users/${userId}/units/${unitsId}`);
};

export const removeUnit = (userId: string, unitsId: string) => {
  return api.delete(`/users/${userId}/units/${unitsId}`);
};

export const listUserEstablishments = <T>(userId: string) => {
  return api.get<T>(`/users/${userId}/establishments`);
};
