import { FieldValues, UseFormSetError } from "react-hook-form";

import { Category } from "interfaces/Category";
import { Establishment } from "interfaces/Establishment";
import { WeekDaysWithCommemorativeDate } from "interfaces/EstablishmentUnit";
import { Plan } from "interfaces/Plan";
import { Voucher, VoucherStatus, VoucherType } from "interfaces/Voucher";
import { handleErrorForm } from "services/api";
import axios from "axios";
import { reverseKeyValueObject, toValueLabel } from "utils/object";

import {
  VoucherFilters,
  create,
  list,
  qrCode,
  remove,
  retrieve,
  update,
} from "./calls";

export * from "./calls";

type UsableDays = {
  start: string;
  end: string;
  monday: boolean;
  tuesday: boolean;
  wednesday: boolean;
  thursday: boolean;
  friday: boolean;
  saturday: boolean;
  sunday: boolean;
  holiday: boolean;
  commemorativeDate: boolean;
};

export default class VoucherAPICaller {
  static adaptFromAPI = async (
    data: Partial<Voucher>,
    shouldAdaptImages?: boolean
  ) => {
    const voucher = {
      ...data,
    };

    voucher.voucherStatus =
      VoucherStatus[data.voucherStatus as keyof typeof VoucherStatus];

    voucher.voucherType = toValueLabel(
      VoucherType[data.voucherType as keyof typeof VoucherType]
    );

    voucher.establishmentId = {
      value: (data.establishment as Establishment)?.id!,
      label: (data.establishment as Establishment)?.name,
    };

    voucher.categoryId = {
      value: (data.category as Category)?.id!,
      label: (data.category as Category)?.name,
    };

    voucher.planId = {
      value: (data.plan as Plan)?.id!,
      label: (data.plan as Plan)?.name,
    };

    voucher.reward = toValueLabel((data.reward || "") as string);

    voucher.usableTimes = data.usableTimes?.map((it) => {
      const week: Record<string, boolean> = {};

      it.days.forEach((day) => {
        week[day] = true;
      });

      return {
        ...it,
        ...week,
      };
    });

    const images: { url: string }[] | File[] = [];

    if (shouldAdaptImages) {
      for (const it of data.images! as { url: string }[]) {
        const fileName = "image";

        if (it.url) {
          await axios
            .get(`${it.url}?not-from-cache-please`, {
              responseType: "blob",
              headers: {
                "Content-type": "application/octet-stream",
                "Sec-Fetch-Mode": "no-cors",
                "Access-Control-Allow-Origin": "*",
                "Cache-Control": "no-cache",
              },
            })
            .then((response) => {
              const file = new File([response?.data], fileName);
              //@ts-ignore
              images.push(file);
            })
            .catch((_) => {});
        }
      }

      voucher.images = images;
    }

    if (data.categoryPriority) {
      voucher.featured = true;
      voucher.categoryPriority = data.categoryPriority;
    }

    return voucher as FieldValues;
  };

  static adaptToAPI = (data: FieldValues) => {
    const voucher = {
      ...data,
    };

    if (data.featured) {
      voucher.categoryPriority = Number(data.categoryPriority);
    } else {
      voucher.categoryPriority = null;
    }

    voucher.voucherStatus =
      reverseKeyValueObject(VoucherStatus)[
        data.voucherStatus.value || data.voucherStatus
      ];

    voucher.voucherType =
      reverseKeyValueObject(VoucherType)[
        data.voucherType.value || data.voucherType
      ];

    voucher.establishmentId = data.establishmentId.value;
    voucher.categoryId = data.categoryId.value;
    voucher.planId = data.planId.value;
    voucher.plan = {
      id: data.planId.value,
    };
    voucher.reward = data.reward?.value;

    if (data.voucherType === "advertising") {
      voucher.reward = "";
      voucher.onPurchaseOf = "";
    }

    const usableTimes: { start: string; end: string; days: string[] }[] = [];

    voucher.usableTimes.forEach((item: UsableDays) => {
      const days: string[] = Object.keys(item).filter(
        (day) =>
          Object.keys(WeekDaysWithCommemorativeDate).includes(day) &&
          item[day as keyof typeof WeekDaysWithCommemorativeDate]
      );

      usableTimes.push({
        start: item.start,
        end: item.end,
        days: days,
      });
    });

    voucher.usableTimes = usableTimes;

    return voucher as Voucher;
  };

  static list = async (filters: VoucherFilters) => {
    const {
      data: { vouchers },
    } = await list({
      ...filters,
      order: filters.order?.replace("plan", "plan.name"),
    });

    return {
      ...vouchers,
      result: await Promise.all(
        vouchers.result.map((item: Voucher) => {
          const adaptedItem = this.adaptFromAPI(item);
          return adaptedItem;
        })
      ),
    };
  };

  static fetch = async (editId: string) => {
    const { data } = await retrieve(editId);
    return await this.adaptFromAPI(data.voucher, false);
  };

  static createOrUpdate = async (
    data: FieldValues,
    setError: UseFormSetError<FieldValues>
  ) => {
    const method = data.id ? update : create;

    const result = method(this.adaptToAPI(data)).catch(
      handleErrorForm(setError)
    );

    return result;
  };

  static remove = async (id: string) => {
    const { data } = await remove(id);

    return data;
  };

  static fetchQRCode = async (id: string) => {
    const { data } = await qrCode(id);

    return data;
  };
}
