import { Convenience } from "interfaces/Convenience";

import { RepositoryParams, api } from "..";

export type ConvenienceFilters = Partial<Convenience & RepositoryParams>;

export const list = async (params: ConvenienceFilters) => {
  return api.get("/conveniences", { params });
};
export const listAll = async () => {
  return api.get("/conveniences/all");
};

export const create = async (params: Convenience) => {
  return api.post("/conveniences", { ...params });
};

export const update = async (params: Convenience) => {
  return api.patch(`/conveniences/${params.id}`, { ...params });
};

export const updateOrders = async (ids: string[]) => {
  return api.put("/conveniences/order", { newOrder: ids });
};

export const retrieve = async (id: string) => {
  return api.get(`/conveniences/${id}`);
};

export const remove = async (id: string) => {
  return api.delete(`/conveniences/${id}`);
};
