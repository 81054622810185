import { isValidCNPJ, isValidCPF } from "@brazilian-utils/brazilian-utils";
import { onlyNumber, removeDuplicates } from "helper";
import * as yup from "yup";

const URL =
  /^^((ftp|http|https):\/\/)?(www.)?(?!.*(ftp|http|https|www.))[a-zA-Z0-9_-]+(\.[a-zA-Z]+)+((\/)[\w#]+)*(\/\w+\?[a-zA-Z0-9_]+=\w+(&[a-zA-Z0-9_]+=\w+)*)?\/?$/i;

const INGNORE_DAYS = ["commemorativeDate", "holiday"];

export const schema = yup.object().shape({
  establishment: yup.object().shape({
    id: yup.string(),
    cnpj: yup
      .string()
      .required("O CNPJ é obrigatório")
      .test("test-invalid-cpf", "CNPJ inválido", (cnpj) => isValidCNPJ(cnpj)),
    companyName: yup
      .string()
      .min(3, "A razão social é obrigatória.")
      .required("A razão social é obrigatória."),
    name: yup
      .string()
      .min(3, "O nome do estabelecimento é obrigatório.")
      .required("O nome do estabelecimento é obrigatório."),
    contactPhone: yup.string(),
    site: yup
      .string()
      .nullable()
      .test(
        "validate-website",
        "Informe um site válido",
        (value = "") => !value || URL.test(value)
      ),
    instagram: yup.string(),
    description: yup.string(),
  }),
  contact: yup.object().shape({
    id: yup.string(),
    name: yup
      .string()
      .min(3, "O nome do responsável é obrigatório.")
      .required("O nome do responsável é obrigatório."),
    email: yup
      .string()
      .trim()
      .email("Infome um email válido.")
      .required("O email do responsável é obrigatório."),
    cpf: yup
      .string()
      .required("O CPF é obrigatório")
      .test("test-invalid-cpf", "CPF inválido", (cnpj) => isValidCPF(cnpj)),
    phone: yup
      .string()
      .required("O telefone é obrigatório")
      .test(
        "test-invalid-phone",
        "Telefone inválido",
        (phone) => String(onlyNumber(phone)).length === 11
      ),
  }),

  voucher: yup.object().shape({
    id: yup.string(),
    onPurchaseOf: yup
      .string()
      .min(3, "O produto é obrigatório.")
      .required("O produto é obrigatório."),
    reward: yup.object().shape({
      label: yup.string().required("O benefício é obrigatório."),
      value: yup.string().required("O benefício é obrigatório."),
    }),
    establishmentRules: yup.string(),
    usableTimes: yup
      .array(
        yup.object().shape({
          id: yup.string(),
          start: yup.string().required("Obrigatório"),
          end: yup.string().required("Obrigatório"),
          days: yup.array().min(1, "O dia do horário é obrigatório."),
        })
      )
      .test({
        message:
          "É obrigatório contemplar ao menos 4 dias de aceitação do benefício!",
        test: (data) => {
          let allCheckDays: string[] = [];

          data?.map(({ days }) => {
            const filtered =
              days?.filter((item) => !INGNORE_DAYS.includes(item)) ?? [];

            allCheckDays.push(...filtered);
          });

          const uniqueList = allCheckDays.filter(
            (value, index, array) => array.indexOf(value) === index
          );

          return uniqueList?.length! >= 4;
        },
      }),
    substitutions: yup
      .array()
      .min(1, "Você precisa selecionar pelo menos uma opção."),
  }),

  holiday: yup.object().shape({
    label: yup.string().required("Selecione uma opção."),
    value: yup.string().required("Selecione uma opção."),
  }),
  commemorativeDate: yup.object().shape({
    label: yup.string().required("Selecione uma opção."),
    value: yup.string().required("Selecione uma opção."),
  }),
  takeaway: yup.object().shape({
    label: yup.string().required("Selecione uma opção."),
    value: yup.string().required("Selecione uma opção."),
  }),

  address: yup.array(
    yup.object().shape({
      zipCode: yup.string().required("O CEP é obrigatório"),
      city: yup.string().required("A cidade é obrigatória"),
      district: yup.string().required("O bairro é obrigatório"),
      street: yup.string().required("O endereço é obrigatório"),
      number: yup.string().required("O número é obrigatório"),
      state: yup.object().shape({
        label: yup.string().required("Selecione uma opção."),
        value: yup.string().required("Selecione uma opção."),
      }),
      // complete address
      contacts: yup.array(
        yup.object().shape({
          id: yup.string(),
          name: yup.string(),
          email: yup
            .string()
            .trim()
            .email("Informe um email válido")
            .default(undefined)
            .nullable(),
          phone: yup.string(),
          contactType: yup
            .object()
            .shape({
              label: yup.string(),
              value: yup.string(),
            })
            .default(undefined)
            .nullable(),
        })
      ),
      openTimes: yup.array(
        yup.object().shape({
          id: yup.string(),
          start: yup.string().required("Obrigatório"),
          end: yup.string().required("Obrigatório"),
          days: yup.array().min(1, "O dia do horário é obrigatório."),
        })
      ),
    })
  ),
});
