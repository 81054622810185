import { useState } from "react";
import { UseFormRegisterReturn } from "react-hook-form";

import { default as Input } from "react-input-mask";

const masks = {
  currency: "R$ 9999999999",
  decimal: "999.99",
  date: "99/99/9999",
  hour: [/[0-2]/, /[0-9]/, ":", /[0-5]/, /[0-9]/],
  zipCode: "99999-999",
  cnpj: "99.999.999/9999-99",
  cpf: "999.999.999-99",
  phone: "(99) 99999-9999",
  color: ["#", ...new Array(6).fill(/[a-f0-9]/i)],
  onlyNumbers: "9999999999",
};

type Masks = typeof masks;

export type Props = {
  className?: string;
  name?: string;
  placeholder?: string;
  id?: string;
  rows?: number;
  label?: string;
  caption?: string;
  disabled?: boolean;
  size?: "large" | "small";
  mask?: keyof Masks;
  type?: string;
  form?: Partial<UseFormRegisterReturn>;
  error?: string;
  handleChange?(value: string): void;
};

const inputMask = (mask: keyof Masks) => masks[mask] ?? "";

export function InputMask({
  error,
  mask,
  form,
  caption,
  size,
  ...props
}: Props) {
  const [parsedMask, setParsedMask] = useState(inputMask(mask as keyof Masks));

  const handleInputChange = (event: React.FormEvent<HTMLInputElement>) => {
    const { value } = event.currentTarget;
    if (mask === "hour") {
      const startWithTwo = value[0] === "2";
      const updatedMask = [
        /[0-2]/,
        startWithTwo ? /[0-3]/ : /[0-9]/,
        ":",
        /[0-5]/,
        /[0-9]/,
      ];
      setParsedMask(updatedMask);
    }
    // @ts-ignore
    form?.onChange && form?.onChange(event);

    props?.handleChange && props.handleChange(value);
  };

  return (
    <div className="oqfc-input-container">
      <Input
        placeholder={props.placeholder}
        className={props.className}
        type={props.type}
        disabled={props.disabled}
        mask={parsedMask}
        {...props}
        {...form}
        onChange={handleInputChange}
      />
      {<span className="text-danger">{error}</span>}
    </div>
  );
}
