import React, { useState } from "react";
import { Controller, useFormContext, useFieldArray } from "react-hook-form";
import { Input, InputMask, Loading } from "..";
import { Convenience } from "components/Convenience";
import { Contact } from "components/Contact";
import { Map } from "components/Map";
import { Option } from "components/InputSelect";
import { OpenTimes } from "components/OpenTimes";
import GeneralAPICaller from "services/api/general";
import { onlyNumber } from "helper";
import { geocodeByAddress } from "react-places-autocomplete";
import { useSelector } from "react-redux";
import { RootState } from "store/index";
import Select, { SingleValue } from "react-select";

interface Props {
  conveniences: Option[];
  shoppings: Option[];
}

export function Establishment({ conveniences, shoppings }: Props) {
  const [loading, setLoading] = useState(false);

  const {
    data: { states },
  } = useSelector((state: RootState) => state.options);

  const parsedState = states.map((state) => ({
    value: state.abbreviation,
    label: state.name,
  }));

  const { control, setValue, clearErrors, watch, register, formState } =
    useFormContext();
  const { fields, append, remove } = useFieldArray({
    control,
    name: "address",
  });

  // const zipCode = watch(`address[${index}].zipCode`)

  function handleAdd() {
    append({
      zipCode: "",
      city: "",
      district: "",
      street: "",
      number: "",
      complement: "",
      latitude: 0,
      longitude: 0,
      contacts: [
        {
          name: "",
          email: "",
          phone: "",
          contactType: {
            label: "",
            value: "",
          },
        },
      ],
      openTimes: [
        {
          start: "",
          end: "",
          days: [],
        },
      ],
    });
  }

  const fetchGeoLocation = async (index: number) => {
    const address = [
      watch(`address[${index}].state`),
      watch(`address[${index}].city`),
      watch(`address[${index}].district`),
      watch(`address[${index}].street`),
      watch(`address[${index}].number`),
    ];

    const result = await geocodeByAddress(address.join(", "));

    if (result && Array.isArray(result) && result.length >= 1) {
      setValue(
        `address[${index}].latitude`,
        result.at(0)?.geometry?.location?.lat()
      );
      setValue(
        `address[${index}].longitude`,
        result.at(0)?.geometry?.location?.lng()
      );
    }
  };

  const fetchZipCode = async (index: number, zipCode: string) => {
    const parsedZipCode = String(onlyNumber(zipCode));
    if (parsedZipCode.length !== 8) return;

    setLoading(true);
    setValue(`address[${index}].state`, "");
    setValue(`address[${index}].city`, "");
    setValue(`address[${index}].district`, "");
    setValue(`address[${index}].street`, "");

    const result = await GeneralAPICaller.searchZipCode(parsedZipCode);

    if (!!result.logradouro) {
      const state = states.filter(
        (state) => state.abbreviation === result.uf
      )[0];

      setValue(`address[${index}].state`, {
        value: state?.abbreviation,
        label: state?.name,
      });
      setValue(`address[${index}].city`, result.localidade);
      setValue(`address[${index}].district`, result.bairro);
      setValue(`address[${index}].street`, result.logradouro);
      clearErrors([
        `address[${index}].zipCode`,
        `address[${index}].state`,
        `address[${index}].city`,
        `address[${index}].district`,
        `address[${index}].street`,
      ]);

      fetchGeoLocation(index);
    } else {
      setValue(`address[${index}].state`, "");
      setValue(`address[${index}].city`, "");
      setValue(`address[${index}].district`, "");
      setValue(`address[${index}].street`, "");
    }

    setLoading(false);
  };

  function handleSearchAddress(index: number, zipcode: string) {
    fetchZipCode(index, zipcode);
  }

  function getErrorMessage(errors: any, index: number, fieldName: string) {
    if (errors?.address) {
      if (errors?.address[index]?.[fieldName]?.message) {
        return errors?.address[index]?.[fieldName]?.message;
      } else if (errors?.address[index]?.[fieldName]?.value) {
        return errors?.address[index]?.[fieldName]?.value.message;
      }
    }
    return "";
  }

  return (
    <>
      {fields.map((item, index) => (
        <>
          <input type="hidden" {...register(`address[${index}].latitude`)} />
          <input type="hidden" {...register(`address[${index}].longitude`)} />

          <div
            className="col-12 d-flex flex-wrap infos-endereco align-items-center justify-content-between"
            key={index}
          >
            {index > 0 && (
              <div className="col-12">
                <div className="lineUnits"></div>
              </div>
            )}
            <div className="row">
              <h4 className="col">
                {index === 0 ? "Endereço Principal" : "Endereço do Local"}
              </h4>
              {index > 0 && (
                <button
                  className="delUnidade"
                  onClick={() => remove(index)}
                  type="button"
                >
                  Excluir esta UNIDADE
                </button>
              )}
            </div>

            <div className="row">
              <div className="col-md-4">
                <InputMask
                  type="text"
                  mask="zipCode"
                  className="input-oqfc"
                  placeholder="CEP (somente numeros)"
                  form={register(`address[${index}].zipCode`)}
                  error={getErrorMessage(formState.errors, index, "zipCode")}
                  handleChange={(value) => handleSearchAddress(index, value)}
                />
              </div>

              <div className="col-md-4 input-select">
                <Controller
                  control={control}
                  name={`address[${index}].state`}
                  render={({ field: { ref, onChange, ...props } }) => (
                    <div className="oqfc-input-container">
                      <Select
                        ref={ref}
                        {...props}
                        placeholder="Estado"
                        className="input-oqfc-select"
                        options={parsedState}
                        onChange={(v: SingleValue<Option>) => onChange(v)}
                      />
                      {getErrorMessage(formState.errors, index, "state") && (
                        <span className="text-danger">
                          {getErrorMessage(formState.errors, index, "state")}
                        </span>
                      )}
                    </div>
                  )}
                />
              </div>

              <div className="col-md-4">
                <Input
                  type="text"
                  className="input-oqfc"
                  placeholder="Cidade"
                  form={register(`address[${index}].city`)}
                  error={getErrorMessage(formState.errors, index, "city")}
                />
              </div>

              <div className="col-md-4">
                <Input
                  type="text"
                  className="input-oqfc"
                  placeholder="Bairro"
                  form={register(`address[${index}].district`)}
                  error={getErrorMessage(formState.errors, index, "district")}
                />
              </div>

              <div className="col-md-8">
                <Input
                  type="text"
                  className="input-oqfc"
                  placeholder="Endere&ccedil;o"
                  form={register(`address[${index}].street`)}
                  error={getErrorMessage(formState.errors, index, "street")}
                />
              </div>

              <div className="col-md-4">
                <Input
                  type="text"
                  className="input-oqfc"
                  placeholder="N&uacute;mero"
                  form={register(`address[${index}].number`)}
                  error={getErrorMessage(formState.errors, index, "number")}
                  // @ts-ignore
                  onBlur={() => {
                    fetchGeoLocation(index);
                  }}
                />
              </div>

              <div className="col-md-4">
                <Input
                  type="text"
                  className="input-oqfc"
                  placeholder="Complemento"
                  form={register(`address[${index}].complement`)}
                />
              </div>

              <h3 className="col-md-7">Pertence a algum centro comercial?</h3>
              <div className="col-md-5 input-select">
                <Controller
                  control={control}
                  name={`address[${index}].shoppingCenter`}
                  render={({ field: { ref, onChange, ...props } }) => (
                    <div className="oqfc-input-container">
                      <Select
                        ref={ref}
                        {...props}
                        placeholder="Se sim, Selecione uma opção"
                        className="input-oqfc-select"
                        isClearable={true}
                        options={shoppings}
                        onChange={(v: SingleValue<Option>) => onChange(v)}
                      />
                      {getErrorMessage(
                        formState.errors,
                        index,
                        "shoppingCenter"
                      ) && (
                        <span className="text-danger">
                          {getErrorMessage(
                            formState.errors,
                            index,
                            "shoppingCenter"
                          )}
                        </span>
                      )}
                    </div>
                  )}
                />
              </div>

              <div className="col-12">
                <Map
                  mapWidth="100%"
                  value={{
                    lat: watch(`address[${index}].latitude`),
                    lng: watch(`address[${index}].longitude`),
                  }}
                  onChange={(coords) => {
                    setValue(`address[${index}].latitude`, coords.lat);
                    setValue(`address[${index}].longitude`, coords.lng);
                  }}
                />
              </div>

              <div className="col-md-6 mt-5">
                <h3>
                  INSTAGRAM <span className="asterisco">*</span>
                </h3>

                <Input
                  type="text"
                  className="input-oqfc"
                  form={register(`address[${index}].instagram`)}
                />
              </div>
              <p className="col-md-6 mt-5 small">
                * Se você usa @ diferentes por unidade, utilize este campo ao
                adicionar novos endereços. Caso seja o mesmo, preencha apenas
                neste endereço principal.
              </p>
            </div>

            <div className="col-12 p-0 d-flex flex-wrap align-items-center">
              <div className="col-12 d-flex flex-wrap align-items-stretch p-0 justify-content-md-between">
                <div className="col-md-6 info-horario d-flex flex-wrap">
                  <div className="col-md-11">
                    <h4>
                      HOR&Aacute;RIO(S) DE FUNCIONAMENTO PARA O P&Uacute;BLICO
                      GERAL
                    </h4>
                    <p>
                      * Se voc&ecirc; abre em hor&aacute;rios diferentes
                      dependendo dos dias da semana, voc&ecirc; pode adicionar
                      outros hor&aacute;rios desta unidade no bot&atilde;o
                      abaixo. Caso tenha outras filiais, clique em
                      &quot;Adicionar mais uma unidade&quot;.
                    </p>
                  </div>
                </div>
                <div className="col-md-5 col-xxl-5">
                  <div className="horarios-dias d-flex flex-wrap">
                    <OpenTimes addressIndex={index} />
                  </div>
                </div>
              </div>
            </div>
            {/* <div className="col-12">
              <button
                className="addHorarios"
                type="button"
                onClick={appendHour}
              >
                ADICIONAR MAIS HOR&Aacute;RIOS NESTA UNIDADE
              </button>
            </div> */}
          </div>
          <Convenience addressIndex={index} options={conveniences} />
          <Contact addressIndex={index} />
        </>
      ))}

      <div className="col-12">
        <button type="button" className="addUnidade" onClick={handleAdd}>
          ADICIONAR MAIS UMA UNIDADE
        </button>
      </div>
      {loading && <Loading />}
    </>
  );
}
