import { Option } from "components/InputSelect";
import React, { useEffect, useState } from "react";
import { Controller, useFormContext } from "react-hook-form";

interface Props {
  addressIndex: number;
  options: Option[];
}

export function Convenience({ addressIndex, options }: Props) {
  const { control, getValues } = useFormContext();

  return (
    <div className="col-12 info-caracteristicas">
      <h3>CARACTERÍSTICAS DESTA UNIDADE</h3>

      <div className="row">
        {options.map((item) => (
          <div
            className="col-md-5 col-xl-6 col-xxl-5 beneficios align-content-start d-flex flex-wrap"
            key={item.value}
          >
            <Controller
              render={({ field }) => (
                <input
                  id={`address[${addressIndex}].conveniences.${item.value}`}
                  type="checkbox"
                  {...field}
                  checked={!!field.value}
                />
              )}
              // @ts-ignore
              name={`address[${addressIndex}].conveniences.${item.value}`}
              control={control}
            />
            <label
              htmlFor={`address[${addressIndex}].conveniences.${item.value}`}
            >
              &nbsp;&nbsp;{item.label}
            </label>
            <br />
          </div>
        ))}
      </div>
    </div>
  );
}
